

export class URLLogger {

    static UpdateInterval: number = 2000;
    static SendServerInterval: number = 300000; // 5min
    static LocalStorageKey: string = 'log-url-store';

    private static _started = false;

    static SendToServer(store: LogUrlStore): any {
        throw new Error(`Not implemented.`);
    }

    static Start() {

        if (URLLogger._started)
            return;

        let begin = new Date();
        setInterval(async () => {

            let end = new Date();

            if (document.hidden) {
                begin = end;
                return;
            }

            let store: LogUrlStore = JSON.parse(localStorage.getItem(URLLogger.LocalStorageKey));
            if (!store)
                store = new LogUrlStore();

            URLLogger.addTime("total", store, begin, end);
            URLLogger.addTime(window.location.href, store, begin, end);
            if (window.location.pathname != "/")
                URLLogger.addTime(window.location.origin + window.location.pathname, store, begin, end);

            begin = end;
            if (end.getTime() - store.lastServerSend >= URLLogger.SendServerInterval) {

                try {
                    // send to the server
                    await URLLogger.SendToServer(store)
                    localStorage.setItem(URLLogger.LocalStorageKey, null);
                } catch (error) {
                    console.error(error);
                }
            } else {
                localStorage.setItem(URLLogger.LocalStorageKey, JSON.stringify(store));
            }

        }, URLLogger.UpdateInterval);
    }

    static addTime(url: string, store: LogUrlStore, begin: Date, end: Date) {

        let usage = store.usages[url];
        if (!usage) {
            usage = {
                lastInterval: { begin: begin.getTime(), end: end.getTime() },
                elapsed: end.getTime() - begin.getTime()
            }
            store.usages[url] = usage;
        }

        let previousBegin = new Date(usage.lastInterval.begin);
        let previousEnd = new Date(usage.lastInterval.end);

        if (begin.getTime() < previousEnd.getTime() && previousBegin.getTime() < end.getTime()) {
            // case of date intersection
            // avoid counting the same time twice
            usage.elapsed -= previousEnd.getTime() - previousBegin.getTime();

            let finalBegin = new Date(Math.min(previousBegin.getTime(), begin.getTime()));
            let finalEnd = new Date(Math.max(previousEnd.getTime(), end.getTime()));

            usage.elapsed += finalEnd.getTime() - finalBegin.getTime();
            usage.lastInterval.begin = finalBegin.getTime();
            usage.lastInterval.end = finalEnd.getTime();
        } else {
            usage.elapsed += end.getTime() - begin.getTime();
            usage.lastInterval.begin = begin.getTime();
            usage.lastInterval.end = end.getTime();
        }
    }
}

class LogUrlStore {
    usages: {
        [url: string]: {
            lastInterval: { begin: number, end: number },
            elapsed: number
        }
    } = {}

    lastServerSend: number = new Date().getTime();
}