import React, { Component } from 'react'
import Template from './Template'
import { connect } from 'react-redux'
import { GenericDocument } from '../crossedTable/GenericDocument.bin'
import { getDetails } from '../../actions/supportActions'
import { TabManager } from '../tabPanel/TabManager.bin'
import TabComponent from '../tabPanel/TabComponent.bin'
import { GenericComponentTab } from '../tabPanel/GenericComponentTab.bin'
import { GetTabManager } from "../../utils/localstorage.bin";

//let dicoTabMgr = {}

class MultiDocumentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        let path = this.props.match.path;
        let tabsMgr = GetTabManager(path);
        if (!tabsMgr) {
            tabsMgr = new TabManager(path);

            const id = decodeURIComponent(this.props.match.params.id)
            this.props.getDetails(id)
            tabsMgr.addTab(new GenericComponentTab(id));

            //dicoTabMgr[path] = tabsMgr;
        }

        this.setState({ tabsMgr });
    }

    render() {

        let { tabsMgr } = this.state;

        return (
            <>
                {tabsMgr && <TabComponent tabManager={tabsMgr} />}
            </>
        )
    }
}

class SingleDocumentComponent extends Component {

    render() {
        const rid = decodeURIComponent(this.props.match.params.id)
        return (
            <GenericDocument rid={rid} />
        )
    }

}

const mapStateToProps = state => ({
    support: state.support
})


export let MultiDocument = connect(
    mapStateToProps,
    { getDetails }
)(Template(MultiDocumentComponent))

export let SingleDocument = connect(
    mapStateToProps,
    { getDetails }
)(Template(SingleDocumentComponent))
