import { GET_SUPPORT_DETAILS, SUPPORT_DETAILS_LOADING, GET_SUPPORT_ISSUES, GET_SUPPORT_OFFERS } from '../actions/types'
import { Client } from 'hub-lib/client/client.bin'

export const getDetails = (id) => dispatch => {
    dispatch(setDetailsLoading())

    Client.getObjects('referentials', {'@rid':id})
        .then(res=>{
            dispatch({
                type: GET_SUPPORT_DETAILS,
                payload: res.data.results[0]
            })
            
        })
        .catch(err => {
            console.log(err)
        })
}
export const setDetailsLoading = () => {
    return {
        type: SUPPORT_DETAILS_LOADING
    }
}
export const getOffers = (id) => dispatch => {
    dispatch(setDetailsLoading())

    Client.getOffers(id)
        .then(res=>{
            dispatch({
                type: GET_SUPPORT_OFFERS,
                payload: res.data.offers
            })
            
        })
        .catch(err => {
            console.log(err)
        })
}
export const getIssues = (id) => dispatch => {
    dispatch(setDetailsLoading())

    Client.getIssues(id)
        .then(res=>{
            dispatch({
                type: GET_SUPPORT_ISSUES,
                payload: res.data.issues
            })
            
        })
        .catch(err => {
            console.log(err)
        })
}